<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted(){
this.suo()
            sessionStorage.setItem("key", "");
            var new_element = document.createElement("script");
            new_element.setAttribute("type", "text/javascript");
            new_element.setAttribute(
              "src",
              "http://g.alicdn.com/code/lib/vConsole/3.4.0/vconsole.min.js"
            );
            document.body.appendChild(new_element);
            window.onload = function () {
              new window.VConsole();
            };
  },
  methods:{
suo() {
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) {
        deviceWidth = 7.5 * 50;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
      var lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          var now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
      if (document.getElementsByTagName("meta")["viewport"]) {
        document.getElementsByTagName("meta")["viewport"].content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
      } else {
        var meta = document.createElement("meta");
        meta.content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0";
        meta.name = "viewport";
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
    },
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // background-color: ;
  background-color: #8efceb;
background-image: linear-gradient(62deg, #8efceb 0%, #E0C3FC 100%);

}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
