<template>
  <div class="bg">
    <!-- 接电话样式 -->
    <div class="bgz" v-if="status == 'jie'">
      <div class="phone_tit">
        {{ answerPhone.toremarks != '' && answerPhone.toremarks != null ? answerPhone.toremarks : answerPhone.name }}邀你{{ type == 'videoCall' ? '视频' : '语音' }}通话
      </div>
      <div class="phone_btn">
        <div class="liebsfs" @click="answer">接听</div>
        <div class="liebsfs" @click="hangUp('s')">拒绝</div>
      </div>
    </div>
    <!-- 打电话样式 -->
    <div class="bgz" v-if="status == 'da'">
      <div class="callOut_tit">
        正在呼叫{{ communicate.remarks != '' && communicate.remarks != null ? communicate.remarks : communicate.collect_name }}
        {{ type == 'videoCall' ? '视频' : '语音' }}通话</div>
      <div class="callOut_loa">正在呼叫中...</div>
      <div class="callOut_btn" @click="hangUp()">取消呼叫</div>
    </div>

    <!-- 视频通话样式 -->
    <div class="bgz" v-if="status == 'video'">
      <img src="../assets/fz.png" class="callvideo_type" />
      <div class="callvideo_tit">正在和{{zdata.status!='jie'?(zdata.remarks != '' &&zdata.remarks != null ? zdata.remarks : zdata.collect_name):(zdata.toremarks != '' && zdata.toremarks != null ? zdata.toremarks : zdata.name)}}视频通话</div>
      <div class="callvideo_box">
        <video playsInline controls autoplay id="callvideo_1" class="callvideo_1" muted="true"></video>
        <video playsInline controls autoplay id="callvideo_2" class="callvideo_2"></video>
      </div>
      <div class="callvideo_loa">正在通话</div>
      <div class="callvideo_btn" @click="hangUp('v')">挂断</div>
    </div>
    <!-- 语音通话样式 -->
    <div class="bgz" v-if="status == 'mp3'">
      <div class="callMp3_tit">正在和{{zdata.status!='jie'?(zdata.remarks != '' &&zdata.remarks != null ? zdata.remarks : zdata.collect_name):(zdata.toremarks != '' && zdata.toremarks != null ? zdata.toremarks : zdata.name)}}语音通话</div>
      <audio src="" id="callMp3_mp3" loop controls preload autoplay class="callMp3_mp3"></audio>
      <div class="callMp3_loa">正在通话</div>
      <div class="callMp3_btn" @click="hangUp('a')">挂断</div>
    </div>
  </div>
</template>
<script>
import Peer from "simple-peer";
export default {
  data() {
    return {
      communicate: {},//打电话
      answerPhone: {},//接电话

      zdata:{},

      status: "",//接电话或者打电话
      type: '',//通话类型 视频 或 语音

      connectionRef: {},
      stream: null,//接听方视频流
      ystream:null,//呼叫方视频流

      streamMp3:null,//接听方语音
      ystreamMp3:null,//呼叫方语音


    };
  },
  mounted() {
    var search = window.location.search; //获取参数；结果：?channelType=test
    let data = this.getSearchString("data", search);
    console.log(JSON.parse(data))
    this.zdata=JSON.parse(data)
    if (JSON.parse(data).status == 'da') {
      this.$socket.emit("communicate", JSON.parse(data));
      // 显示拨打电话界面
      this.status = 'da'
      this.type = JSON.parse(data).type
      this.communicate = JSON.parse(data)
      

      if (this.type == 'videoCall') {
        console.log('拨打视频电话   获取权限')
        this.videotype()
        this.monitor()
      } else {
        console.log('拨打语音电话   获取权限')
        this.mp3type()
        this.monitor()
      }
    } else {
      console.log('接电话')
      //先通知后台我已经到了电话界面
      this.$socket.emit("answerPhone", JSON.parse(data));
      // 显示接听是否同意界面，如果点同意就吧视频流给对方
      this.status = 'jie'
      this.type = JSON.parse(data).type
      this.answerPhone = JSON.parse(data)

      if (this.type == 'videoCall') {
        console.log('接听视频电话   获取权限')
        
        // this.videotype()
      } else {
        console.log('接听语音电话   获取权限')
        // this.mp3type()
      }
    }

  },

  methods: {
    hangUp(a){

      // console.log('挂断电话')
      // console.log('挂断电话',document.getElementById('callvideo_2'))
      // console.log('挂断电话',a,document.getElementById('callvideo_2').currentTime)


      if(a!='s'){
        let time=null
      if(a=='a'){
        if(document.getElementById('callMp3_mp3')){
          if(document.getElementById('callMp3_mp3').currentTime){
            time=this.stohs(Math.floor(document.getElementById('callMp3_mp3').currentTime))
          }
          
        }
        
        // console.log(this.stohs(Math.floor(document.getElementById('callMp3_mp3').currentTime))); //获取播放的时间  
      }else{
        if(document.getElementById('callvideo_2')){
          // console.log(7896,time)
          if(document.getElementById('callvideo_2').currentTime){
            // console.log(123)
            time=this.stohs(Math.floor(document.getElementById('callvideo_2').currentTime))
            // console.log(465,time)

          }

        }
        
        // console.log(this.stohs(Math.floor(document.getElementById('callvideo_2').currentTime))); //获取播放的时间  
      }

      let data={
        zdata:this.zdata,
        time:time,
      }
      // console.log('挂断电话xx',time,a,document.getElementById('callvideo_2').currentTime)
// console.log(data)

// return
      this.$socket.emit("hangUpPhone", data);
      console.log('我执行了吗a',data)
      }else{

        let data={
        zdata:this.zdata,
        refuse:'refuse',
      }
      console.log('我执行了吗',data)
      this.$socket.emit("hangUpPhone", data);
      }

    },
    // 监听接听方传过来的视频流
    monitor(){
            // 监听呼叫方传输过来的数据
            this.sockets.subscribe("callAccepted", (res) => {
        console.log("wa");
        console.log(res, 1);
        
        // this.cid = res.from
        // this.cname = res.name;
        // this.xinling=res.signal;
        // if (res.typp == "v") {
          
          if(this.type == 'videoCall'){
            this.ystream = res.signalData;
          }else{
            this.ystreamMp3 = res.signalData;
          }
          //收到了接听方反回的视频流 
          
          
          //吧我的视频流发给接听方 
          this.returncall()
        
          // } else {
        //   this.yatream = res.signal;
        // }
        // this.youtype = res.typp;
        // if(this.jinru==6){
        //     this.jt()
        // }else{
        //    this.jinru = 4;
        // }
       
        // this.coid=res
      });
    },
    // 点击接听电话返回视频流
    answer() {


      let $this = this

      if ($this.type == 'videoCall') {
        // 视频接听呼叫------并吧我的视频流放在桌面上的video标签中------------------------------------------------------------
        
        $this.videotype('jie')
        console.log('wang')

      } else {
        // 语音接听呼叫------并吧我的语音流放在桌面上的auto标签中------------------------------------------------------------
        
              $this.mp3type('jie')
        console.log('wang')
      }
    },
//吧我的视频流发给接听方
    returncall() {
      let $this=this

      if($this.type == 'videoCall'){
        // 实例化对等连接对象
        $this.speers = new Peer({
          initiator: false,
          config: {
            iceServers: [
              {
                urls: "turn:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
              {
                urls: "stun:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
            ],
          },
          stream: $this.stream,
          trickle: false,
        });
        //

        console.log('吧我的视频流发给接听方')
        // 传递信令数据
        $this.speers.on("signal", (data) => {
          console.log('吧我的视频流发给接听方')
          $this.$socket.emit("returncall", {
            signalData: data,
            datas: $this.communicate,
          });
        });

        $this.speers.on("stream", (stream) => {
          console.log(1,123);
          // console.log(document.getElementById("videos"));
          console.log(stream);
          $this.status = 'video'
          setTimeout(() => {
            document.getElementById("callvideo_2").srcObject = stream;
            document.getElementById("callvideo_1").srcObject = $this.stream;
          }, 10);
        });
        console.log($this.ystream);
        $this.speers.signal($this.ystream);
        // peer.write($this.xinling)
        //      $this.connectionRef.current=peer
        // },100)
        // return

        // 储存peer对象
        $this.connectionRef.current = $this.speers;
      }else{






        // 实例化对等连接对象
        $this.speers = new Peer({
          initiator: false,
          config: {
            iceServers: [
              {
                urls: "turn:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
              {
                urls: "stun:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
            ],
          },
          stream: $this.streamMp3,
          trickle: false,
        });
        //

        console.log('吧我的视频流发给接听方')
        // 传递信令数据
        $this.speers.on("signal", (data) => {
          console.log('吧我的视频流发给接听方')
          $this.$socket.emit("returncall", {
            signalData: data,
            datas: $this.communicate,
          });
        });

        $this.speers.on("stream", (stream) => {
          console.log(1,123);
          // console.log(document.getElementById("videos"));
          console.log(stream);
          $this.status = 'mp3'
          setTimeout(() => {
            document.getElementById("callMp3_mp3").srcObject = stream;
            // document.getElementById("callvideo_1").srcObject = $this.streamMp3;
          }, 10);
        });
        console.log($this.ystreamMp3);
        $this.speers.signal($this.ystreamMp3);
        // peer.write($this.xinling)
        //      $this.connectionRef.current=peer
        // },100)
        // return

        // 储存peer对象
        $this.connectionRef.current = $this.speers;













      }

    
    },


    // 获取当前视频权限
    videotype(a) {
      console.log('lllllllllllllllllllldddd')
      let $this = this
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: { facingMode: "user" } })
        .then(function (streams) {
          console.log(1);
          console.log(streams);


          if (a == 'jie') {
            $this.stream = streams;
            // if (document.getElementById("callvideo_2")) {
            //   document.getElementById("callvideo_2").srcObject = streams;
            //   document.getElementById("callvideo_2").play();
            // }
          


          // 实例化对等连接对象

        $this.speer = new Peer({
          initiator: true,
          config: {
            iceServers: [
              {
                urls: "turn:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
              {
                urls: "stun:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
            ],
          },
          stream: $this.stream,
          trickle: false,
        });
        // 传递信令数据

        $this.speer.on("signal", (data) => {
          $this.$socket.emit("callUser", {
            signalData: data,
            datas:$this.answerPhone,
          });
        });




        // 获取对方的stream
        $this.speer.on("stream", (stream) => {
          console.log(stream);
          console.log("拿到对方的数据2", stream);
          $this.ystream = stream;
          $this.status = 'video'
          setTimeout(() => {
          document.getElementById("callvideo_1").srcObject = $this.stream;
          document.getElementById("callvideo_2").srcObject = $this.ystream;

          },10)
          //  document.getElementById("videos").srcObject = stream;
        });
        //当接听方同意通话后获取信令
        $this.sockets.subscribe("callAcceptedTo", (signal) => {
          // $this.jinru = 6;
          console.log("接听正在获取对方的数据2");
          $this.speer.signal(signal.signalData);
          console.log("接听正在获取对方的数据2");
        });
        // 储存peer对象
        $this.connectionRef.current = $this.speers;
























          }else{
            $this.stream=streams
          }
          // $this.stream = streams;
          // if (document.getElementById("video")) {
          //   document.getElementById("video").srcObject = streams;
          //   document.getElementById("video").play();
          // }
        })
        .catch(function (err) {
          console.log(err);
          console.log('调取摄像图失败');
          /* 处理 error */
        });
    },
    // 获取当前音频权限
    mp3type(a) {
      let $this=this
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (streams) {
          console.log(1);
          console.log(streams);





          if (a == 'jie') {
            $this.streamMp3=streams
            // if (document.getElementById("callvideo_2")) {
            //   document.getElementById("callvideo_2").srcObject = streams;
            //   document.getElementById("callvideo_2").play();
            // }
          


          // 实例化对等连接对象

        $this.speer = new Peer({
          initiator: true,
          config: {
            iceServers: [
              {
                urls: "turn:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
              {
                urls: "stun:sp.lelele.vip:3522",
                username: "wang",
                credential: "123456",
              },
            ],
          },
          stream: $this.streamMp3,
          trickle: false,
        });
        // 传递信令数据

        $this.speer.on("signal", (data) => {
          $this.$socket.emit("callUser", {
            signalData: data,
            datas:$this.answerPhone,
          });
        });




        // 获取对方的stream
        $this.speer.on("stream", (stream) => {
          console.log(stream);
          console.log("拿到对方的数据2", stream);
          $this.ystreamMp3 = stream;
          $this.status = 'mp3'
          setTimeout(() => {
          // document.getElementById("callvideo_1").srcObject = $this.stream;
          document.getElementById("callMp3_mp3").srcObject = $this.ystreamMp3

          },10)
          //  document.getElementById("videos").srcObject = stream;
        });
        //当接听方同意通话后获取信令
        $this.sockets.subscribe("callAcceptedTo", (signal) => {
          // $this.jinru = 6;
          console.log("接听正在获取对方的数据2");
          $this.speer.signal(signal.signalData);
          console.log("接听正在获取对方的数据2");
        });
        // 储存peer对象
        $this.connectionRef.current = $this.speers;










          }else{
            $this.streamMp3=streams
          }



          // $this.stream = streams;
          // if (document.getElementById("video")) {
          //   document.getElementById("video").srcObject = streams;
          //   document.getElementById("video").play();
          // }

        })
        .catch(function (err) {
          console.log(err);
          console.log('调取音频失败');

        });
    },
    stohs(s) {
				//计算分钟
				//算法：将秒数除以60，然后下舍入，既得到分钟数
				let h;
				h = Math.floor(s / 60);
				//计算秒
				//算法：取得秒%60的余数，既得到秒数
				s = s % 60;
				//将变量转换为字符串
				h += "";
				s += "";
				//如果只有一位数，前面增加一个0
				h = h.length == 1 ? "0" + h : h;
				s = s.length == 1 ? "0" + s : s;
				return h + ":" + s;
			},
    // 获取页面参数
    getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    },

  },
};
</script>
<style scoped>
.callMp3_btn {
  width: 2.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.3rem;
  font-size: 0.4rem;
  color: #ff3f0f;
  font-weight: bold;
  position: absolute;
  top: 11.5rem;
  left: 50%;
  transform: translate(-50%);
  background-image: linear-gradient(62deg, #dbd2c5 0%, #ecc3d4 100%);
}

.callMp3_loa {
  font-size: 0.5rem;
  color: #e9baeb;
  /* font-weight: bold; */
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%);
}

.callMp3_mp3 {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translate(-50%);
}

.callMp3_tit {
  font-size: 0.5rem;
  color: #c814ff;
  font-weight: bold;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%);
}


.callvideo_btn {
  width: 2.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.3rem;
  font-size: 0.4rem;
  color: #ff3f0f;
  font-weight: bold;
  position: absolute;
  top: 11.5rem;
  left: 50%;
  transform: translate(-50%);
  background-image: linear-gradient(62deg, #dbd2c5 0%, #ecc3d4 100%);
}

.callvideo_loa {
  font-size: 0.5rem;
  color: #e9baeb;
  /* font-weight: bold; */
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%);
}

.callvideo_2 {
  width: 3.45rem;
  height: 7rem;
  position: absolute;
  right: 0;
  top: 0;
}

.callvideo_1 {
  width: 3.45rem;
  height: 7rem;
  position: absolute;
  left: 0;
  top: 0;
}

.callvideo_box {
  position: absolute;
  width: 7rem;
  height: 7rem;
  top: 2.5rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #9a65cc;
}

.callvideo_tit {
  font-size: 0.5rem;
  color: #c814ff;
  font-weight: bold;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%);
}

.callvideo_type {
  position: absolute;
  width: 1rem;
  right: 0.5rem;
  top: 1rem;
}


.callOut_btn {
  width: 2.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.3rem;
  font-size: 0.4rem;
  color: #ff3f0f;
  font-weight: bold;
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%);
  background-image: linear-gradient(62deg, #dbd2c5 0%, #ecc3d4 100%);
}

.callOut_loa {
  font-size: 0.5rem;
  color: #e9baeb;
  /* font-weight: bold; */
  position: absolute;
  top: 8.5rem;
  left: 50%;
  transform: translate(-50%);
}

.callOut_tit {
  font-size: 0.5rem;
  color: #c814ff;
  font-weight: bold;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%);
}



.phone_btn {
  width: 4.5rem;
  height: 0.8rem;
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  /* background-color: #6361ca; */
  justify-content: space-between;
}

.liebsfs {
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.3rem;
  font-size: 0.4rem;
  color: #ff3f0f;
  font-weight: bold;
  /* position: absolute; */
  /* top:10rem;
    left: 50%;
  transform: translate(-50%); */
  background-image: linear-gradient(62deg, #dbd2c5 0%, #ecc3d4 100%);
}

.phone_tit {
  font-size: 0.5rem;
  color: #c814ff;
  font-weight: bold;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%);
}

.bgz {
  width: 100%;
  height: 13.34rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background-color: rebeccapurple; */
}

.bg {
  background-image: linear-gradient(62deg, #8efceb 0%, #e0c3fc 100%);
  background-size: cover;
  background-position: center center;
  /* background-color: #67aae0; */
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 13.34rem;
  max-width: 7.5rem;
  left: 0rem;
  overflow: hidden;
}
</style>