import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import Vant from 'vant';
import 'vant/lib/index.css';

// import Vue from 'vue';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
// import App from './App.vue';
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
 
Vue.use(new VueSocketIO({
debug: true,
connection: SocketIO('https://qq.lelele.vip')
}));

// import VueSocketIO from 'vue-socket.io'
// import { io } from "socket.io-client";

// const socket = io("http://172.18.10.9:3000", {
//   reconnectionDelayMax: 10000,

// });
// Vue.prototype.$socket = socket;
// Vue.use(new VueSocketIO({
//     debug: true,
//     // 服务器端地址
//     connection: 'http://172.18.10.9:3000',
//     vuex: {
//         store,
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_'
//     },
// }))


Vue.use(ElementUI);

// new Vue({
//   el: '#app',
//   render: h => h(App)
// });


Vue.use(Vant);
import axios from 'axios'
// Vue.prototype.$axios = axios
// axios.defaults.baseURL = 'http://localhost:8080/#/';
// Vue.config.productionTip = false

new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App)
}).$mount('#app')